import Vue from 'vue';
import Vuex from 'vuex';
import createStore from 'vuex-persistedstate';

import base from './base/index'; // 项目公共
import common from './common/index'; // 业务公共

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    base,
    common
  },
  plugins: [
    createStore({
      key: 'vuex-store', // 存数据的key名
      paths: [] // 要把那些模块加入缓存
    })
  ]
});
