import STRING from "string";
let originParams = {};
let headers = {};
export const getOrigin = function() {
  let userAgents = navigator.userAgent;
  console.log('userAgents: ', userAgents);

  if (process.env.NODE_ENV === "development") { //只允许再测试环境
      // userAgents = 'UA: FXEYE-BIB-IOS-WIKIFX{"wikistock":"ios","language":"zh-CN","userId":"8336428062","wikinum":"1967006133","falg":"https://img.tech002.com/flag/7d8833382673bab2/CN.png_wiki-template-global","phonecode":"0086","hasWhatsApp":true,"showname":"迟昂","LanguagePreference":"zh-CN","nick":"9527..","avatar":"https://img.fx696.com//avatar/8336428062/8336428062_51842.jpg_wiki200","countrycode":"156","twoCharCode":"CN","BasicData":"0,1401,3,352,0,be251f90-5585-11ef-9cb4-3d2bee0cacbd,0","navHeight":44.0,"countryName":"中国","oneSignalUserId":"aa003020-a098-4cda-a410-dc79410cb141"}{"wikistock":"ios","language":"zh-CN","userId":"8336428062","wikinum":"1967006133","falg":"https://img.tech002.com/flag/7d8833382673bab2/CN.png_wiki-template-global","phonecode":"0086","hasWhatsApp":true,"showname":"迟昂","LanguagePreference":"zh-CN","nick":"9527..","avatar":"https://img.fx696.com//avatar/8336428062/8336428062_51842.jpg_wiki200","countrycode":"156","twoCharCode":"CN","BasicData":"0,1401,3,352,0,be251f90-5585-11ef-9cb4-3d2bee0cacbd,0","navHeight":44.0,"countryName":"中国","oneSignalUserId":"aa003020-a098-4cda-a410-dc79410cb141"}'
  }

  if (userAgents.includes('FXEYE-BIB-IOS-WIKIFX') || userAgents.includes('FXEYE-BIB-ANDROID-WIKIFX') || userAgents.includes('WIKIBIT-BIB-IOS') || userAgents.includes('WIKIBIT-BIB-ANDROID')) {
    originParams = '{' + STRING(userAgents).between('{', '}').s + '}'
    originParams = JSON.parse(originParams)
    originParams.language = originParams.language?.toLowerCase()
    console.log('originParams: ', originParams);
    let newArr = []
    if (originParams?.labels?.length > 0) {
      originParams?.labels.forEach(el => {
        newArr.push({
          data: [
            {
              labelName: el
            }
          ]
        })
      });
    }

    if (originParams.language == 'zh-tw' || originParams.language == 'zh-hk') originParams.language = 'zh'
    headers = {
      language: originParams.language || 'zh-cn',
      userId: originParams.userId || null,
      countrycode: originParams.countrycode,
      wikinum: originParams.wikinum || null,
      falg: originParams.falg,
      phonecode: originParams.phonecode,
      myCountry: originParams.countrycode,
      LanguagePreference: originParams.LanguagePreference,
      BasicData: originParams.BasicData,
      navHeight: originParams.navHeight,
      nick: originParams?.nick || '',
      avatar: originParams?.avatar || '',
    } || {}
    console.log(headers,'headers');
    console.log('userAgents::: ', userAgents);
    return headers
  }
}