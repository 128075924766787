let Index = () => import(/* webpackChunkName: "dome" */ '@/views/_lang/index.vue');
let enroll = () => import(/* webpackChunkName: "dome" */ '@/views/_lang/enroll.vue');
let appenroll = () => import(/* webpackChunkName: "dome" */ '@/views/_lang/appenroll.vue');
let writeoff = () => import(/* webpackChunkName: "dome" */ '@/views/_lang/writeoff.vue');
let forex = () => import(/* webpackChunkName: "dome" */ '@/views/_lang/forex.vue');
let picturelive = () => import(/* webpackChunkName: "dome" */ '@/views/_lang/picturelive.vue');
let piclivedealer = () => import(/* webpackChunkName: "dome" */ '@/views/_lang/piclivedealer.vue');
let test = () => import(/* webpackChunkName: "dome" */ '@/views/_lang/test.vue');
export default [
  { path: '/:lang', name: 'Index', component: Index, meta: { isLogin: false, level: '2,4,999' } },
  { path: '/:lang/enroll', name: 'enroll', component: enroll, meta: { isLogin: false, level: '2,4,999' } },
  { path: '/:lang/appenroll', name: 'appenroll', component: appenroll, meta: { isLogin: false, level: '2,4,999' } },
  { path: '/:lang/writeoff', name: 'writeoff', component: writeoff, meta: { isLogin: false, level: '2,4,999' } },
  { path: '/:lang/forex', name: 'forex', component: forex, meta: { isLogin: false, level: '2,4,999' } },
  { path: '/:lang/picturelive', name: 'picturelive', component: picturelive, meta: { isLogin: false, level: '2,4,999' } },
  { path: '/:lang/piclivedealer', name: 'piclivedealer', component: piclivedealer, meta: { isLogin: false, level: '2,4,999' } },
  { path: '/:lang/test', name: 'test', component: test, meta: { isLogin: false, level: '2,4,999' } },
];
