import $cookies from 'js-cookie';
const state = {
  langList: [
    'ar', // 阿拉伯语
    'de', // 德语
    'en',
    'es',
    'fil',
    'fr', // 法语
    'hi', // 印地
    'id',
    'it', // 意大利
    'ja',
    'ko', // 韩语
    'pt', // 葡萄牙语
    'tr', // 土耳其
    'ru',
    'th',
    'vi',
    'zh',
    'zh-cn',
    'zh-hk',
    'zh-tw'
  ], // 国际版语言列表
  isWiki: 1, // (1:wikifx;2:包含普通fxeye；3:包含wikifxvn.com,wikifxvi.com,wikifxen.com, 4:fxeye中推广用的特殊域名)
  myCountry: 'cn', // 用户所在的国家（不变的值）
  mobile: false, // 是否为移动端pc---false；m站---true
  CountryCode: 'cn', // 国家
  host: '', // 主域名如wikifx.com
  LanguageCode: 'en', // 语言
  nodeEnv: '', // 用户ip
  userIp: '114.114.114.114', // 用户ip
  contentcookie: '1111', // 用户登录cookie值
  cookieHost: '/', // 处理好存cookie需要的路径域名
  user: {
    userId: ''
  }, // userId用户id nick用户名称 avatar用户头像  3796064528
  footCountry: [], // 底部国家
  loghost: 'http://192.168.200.151:8087', // 日志请求地址
  useragent: '', // 请求代理header['user-agent']
  getCountry: {
    "countryCode": "156",
    "twoCharCode": "CN",
    "code": "0086",
    "name": "中国",
    "flag": "https://img.souhei.com.cn/flag/7d8833382673bab2/CN.png_wiki-template-global",
    "flag-c": "https://img.souhei.com.cn/flag-c/7d8833382673bab2.png_wiki-template-global"
  }, // 获取国家code，国家名称，国家代码，国旗  
  usergetCountry: {
    "countryCode": "156",
    "twoCharCode": "CN",
    "code": "0086",
    "name": "中国",
    "flag": "https://img.souhei.com.cn/flag/7d8833382673bab2/CN.png_wiki-template-global",
    "flag-c": "https://img.souhei.com.cn/flag-c/7d8833382673bab2.png_wiki-template-global"
  }, // 用户中心获取国家code，国家名称，国家代码，国旗 
  navHeight: 0, //导航安全高度
  enterpriseCode: '', // 服务商Code
  enterpriseType: 1, // 1=>服务商; 2=>交易商;
  userId: '', // 用户登录后的Id  3796064528
  navTitle: '', // 导航栏名称
  preferredLanguageCode: '', // app传值过来的偏好语言
  wikinum: '',
  isApp:false,
  expoInfo:{}, // 图片数据
};

const mutations = {
  setexpoInfo(state,value){
    state.expoInfo = value
    console.log('state.expoInfo: ', state.expoInfo);
  },
  setisApp(state,value){
    state.isApp = value
  },
  setMobile (state, value) {
    // 设置移动端还是pc端
    state.mobile = value
  },
  setEnterpriseType (state, value) {
    state.enterpriseType = value;
  },
  sethost (state, value) { // 设置主域名
    state.host = value;
  },
  setwikinum (state, value) { // 设置天眼号
    state.wikinum = value;
  },
  setUsercookie (state, value) { // 设置用户信息cookie
    state.contentcookie = value;
  },
  setEnterpriseCode (state, params) { // 设置服务商Code  0001390005有开户记录  0001326398 vps列表
    state.enterpriseCode = params;
  },
  setNodeEnv (state, value) { // 获取环境变量
    state.nodeEnv = value || '';
    $cookies.set('nodeEnv', state.nodeEnv, { path: '/', domain: state.cookieHost });
  },
  setcookieHost (state, value) { // 处理好存cookie需要的路径域名.wikifxen.com
    state.cookieHost = value;
  },
  setUser (state, value) { // 设置用户信息
    state.userId = value.userId;
    state.user = Object.assign({}, value);
  },
  setUserid (state, value) { // 设置用户信息
    state.userId = value;
  },
  setInfo (state, value) { // 获取国家code，国家名称，国家代码，国旗
    state.getCountry = Object.assign({}, value);
  },
  setuserCountInfo (state, value) { // 用户中心获取国家code，国家名称，国家代码，国旗 
    state.usergetCountry = Object.assign({}, value);
  },
  getIp (state, value) { // 获取用户ip
    state.userIp = value;
  },
  getuserAgent (state, value) { // 请求头代理
    state.useragent = value;
  },
  // 存储用户所在的国家
  setMyCountry (state, value) {
    state.myCountry = value?.toLowerCase();
    $cookies.set('CURRIPCOUNTRY', value?.toLowerCase(), { path: '/', domain: state.cookieHost });
  },
  // 设置语言code
  setLangCode (state, value) {
    let lang = value?.toLowerCase();
    if (lang == 'zh-tw' || lang == 'zh-hk') {
      lang = 'zh';
    }
    state.LanguageCode = lang;
    $cookies.set('LanguageCode', state.LanguageCode, { path: '/', domain: state.cookieHost });
  },
  // 设置国家code
  setCountryCode (state, value) {
    state.CountryCode = value;
    $cookies.set('CountryCode', value, { path: '/', domain: state.cookieHost });
  },
  setNavTitle (state, params) { // 设置navbar名称
    state.navTitle = params;
  },
  getPreferredLanguageCode (state, params) { // 设置偏好语言仅APP传值过来
    state.preferredLanguageCode = params;
  },
  getNavHeight (state, params) { // app的安全区域
    state.navHeight = params;
  }
};

const actions = {
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
