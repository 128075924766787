export const messages = {
  'zh-cn': require('@/assets/languages/TXT_zh-CN.json'),
  'zh-hk': require('@/assets/languages/TXT_zh-HK.json'),
  'zh-tw': require('@/assets/languages/TXT_zh-TW.json'),
  ar: require('@/assets/languages/TXT_ar.json'),
  en: require('@/assets/languages/TXT_en.json'),
  de: require('@/assets/languages/TXT_de.json'),
  es: require('@/assets/languages/TXT_es.json'),
  fil: require('@/assets/languages/TXT_fil.json'),
  fr: require('@/assets/languages/TXT_fr.json'),
  hi: require('@/assets/languages/TXT_hi.json'),
  id: require('@/assets/languages/TXT_id.json'),
  it: require('@/assets/languages/TXT_it.json'),
  ja: require('@/assets/languages/TXT_ja.json'),
  ko: require('@/assets/languages/TXT_ko.json'),
  ms: require('@/assets/languages/TXT_ms.json'),
  pt: require('@/assets/languages/TXT_pt.json'),
  ru: require('@/assets/languages/TXT_ru.json'),
  th: require('@/assets/languages/TXT_th.json'),
  vi: require('@/assets/languages/TXT_vi.json'),
  zh: require('@/assets/languages/TXT_zh.json'),
  tr: require('@/assets/languages/TXT_tr.json')
};