<template>
  <div id="app">
    <!-- <div :style="{height:(44 + $store.state.base.user.navHeight)+'px'}" ></div> -->
    <!-- <keep-alive> -->
      <router-view :key="$route.fullPath"></router-view>
    <!-- </keep-alive> -->
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
    };
  },
  mounted () {
    let _this = this
    this.setIsMobile()
    window.addEventListener('resize', function () {
      _this.setIsMobile()
    }, { passive: true })
  },
  methods: {
    setIsMobile () {
      if (document.documentElement.clientWidth > (799 - this.getScrollbarWidth())) {
        this.$store.commit('base/setMobile', false)
      } else {
        this.$store.commit('base/setMobile', true)
      }
    },
    getScrollbarWidth () { // 计算滚动条宽度
      const scrollDiv = document.createElement('div')
      scrollDiv.style.cssText = 'height: 99px; overflow: scroll; position: absolute; top: -9999px;'
      document.body.appendChild(scrollDiv)
      const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
      document.body.removeChild(scrollDiv)
      return scrollbarWidth
    },
  },
};
</script>


