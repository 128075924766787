const state = {
    testValue: {}, // 测试数据
  };
  
  const mutations = {
    set_testValue (state, params) { // 设置测试数据
      state.testValue = params;
    }
  };
  
  const actions = {
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions
  };
  